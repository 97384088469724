import React, { HTMLAttributes, ReactElement } from "react";
import { NavLink, To, useLocation } from "react-router-dom";
import {
  Box,
  IconButton,
  Divider as MuiDivider,
  Link,
  LinkTypeMap,
  List,
  Stack,
  styled,
  Tooltip,
  useTheme,
} from "@mui/material";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { HEADER_HEIGHT } from "~/components/AppHeader";
import TextOverflow from "~/components/TextOverflow";

export const COLLAPSED_WIDTH = "57px";
export const EXPANDED_WIDTH = "210px";
interface NavItemProps {
  title: string;
  to: To;
  icon: ReactElement;
  overrideHighlight?: string | false;
  showTitle?: boolean;
  tooltip?: ReactElement | string;
  sx?: any;
}

const StyledLink = styled(Link)({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  svg: {
    height: 22,
    width: 22,
  },
  "& > div": {
    backgroundColor: "transparent",
    transition: "background-color .2s linear",
  },
  "&:hover > div": {
    backgroundColor: "#e4e4e6",
  },
  "&.active > div": {
    backgroundColor: "#E8E5FF",
    "svg, p": {
      color: "#5030c3",
    },
  },
}) as OverridableComponent<LinkTypeMap>;

const FormattedTitle = ({ content }: { content: React.ReactNode }) => {
  if (typeof content === "string") {
    return <TextOverflow variant="body2">{content}</TextOverflow>;
  } else {
    return <>{content}</>;
  }
};

export const NavItem = ({
  title,
  to,
  icon,
  overrideHighlight,
  showTitle,
  tooltip,
  sx = {},
}: NavItemProps) => {
  const { pathname } = useLocation();
  // Optionally extend default react-router NavLink matching behavior
  const highlight = overrideHighlight ? pathname.startsWith(overrideHighlight) : false;

  return (
    <Tooltip title={tooltip} enterDelay={500} placement="right" arrow disableInteractive>
      <Box component="li" display="flex" width="100%">
        <StyledLink
          component={NavLink}
          to={to}
          className={highlight ? "active" : undefined}
          sx={{ display: "block", width: "100%", ...sx }}
          aria-label={title}
          underline="none"
        >
          <Box
            display="flex"
            gap={1}
            px={showTitle ? 1.125 : 0}
            height={40}
            minWidth={40}
            alignItems="center"
            whiteSpace="nowrap"
            justifyContent={showTitle ? "left" : "center"}
            borderRadius="6px"
            color="primary.dark"
            sx={{
              "& svg": {
                flexShrink: 0,
              },
            }}
          >
            {icon}
            {showTitle && <FormattedTitle content={title} />}
          </Box>
        </StyledLink>
      </Box>
    </Tooltip>
  );
};

const Divider = () => (
  <MuiDivider flexItem sx={{ bgcolor: "primary.dark", opacity: 0.125, mx: 0.5, my: 0.25 }} />
);

const SideNav = ({
  sections,
  collapsed = false,
  onCollapseToggle,
}: HTMLAttributes<HTMLElement> & {
  sections: (ReactElement<NavItemProps>[] | ReactElement<NavItemProps>)[];
  collapsed: boolean;
  onCollapseToggle: () => void;
}) => {
  const theme = useTheme();
  const transitionDuration = collapsed
    ? theme.transitions.duration.leavingScreen
    : theme.transitions.duration.enteringScreen;

  return (
    <Box
      component="nav"
      sx={{
        pt: 1,
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "sticky",
        zIndex: theme.zIndex.appBar,
        flex: `0 0 ${collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH}`,
        transition: `flex ${transitionDuration}ms`,
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        bgcolor: "common.toolbarBackground",
        overflowX: "hidden",
        overflowY: "auto",
        borderRight: `1px solid ${theme.palette.common.toolbarBorder}`,
        "& li": {
          width: collapsed ? "fit-content" : "100%",
        },
      }}
    >
      <Stack component={List} disablePadding width="100%" gap={0.5} px={1}>
        {sections.flatMap((section, index) => {
          const last = index === sections.length - 1;
          if (!last) {
            return [section, <Divider key={`divider-${index}`} />];
          }
          return [section];
        })}
      </Stack>
      <Box
        pl="15px"
        pt={1}
        pb={1}
        mb={0.5}
        position="sticky"
        bottom={0}
        bgcolor="common.toolbarBackground"
      >
        <IconButton
          aria-label="toggle navigation collapse"
          onClick={onCollapseToggle}
          sx={{
            "& svg": {
              fontSize: 18,
              opacity: 0.5,
              transition: `transform ${transitionDuration}`,
              transform: `rotate${collapsed ? "(0)" : "(180deg)"}`,
            },
            "&:hover svg": {
              opacity: 1,
            },
          }}
        >
          <KeyboardDoubleArrowRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SideNav;
