import { useFetcher } from "~/hooks/useFetcher";
import { CohortSummary } from "cohorts/types/interfaces/cohort";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { SortMethod, SortOrder } from "cohorts/types/enums/sort";
import queryString from "query-string";

const useCohorts = () => {
  const [query] = useQueryParams({
    sort: withDefault(StringParam, SortMethod.LAST_UPDATED),
    order: withDefault(StringParam, SortOrder.DESC),
  });

  const { sort, order } = query;

  const qs = queryString.stringify({
    sort,
    order,
  });

  return useFetcher<CohortSummary[]>(`/api/cohort-explorer/cohorts?${qs}`);
};

export default useCohorts;
