import { useFetcher } from "~/hooks/useFetcher";
import ParticipantBreakdown from "cohorts/types/interfaces/participant-breakdown";

const useTotalParticipantsCount = () => {
  return useFetcher<{ participant_count: number; participant_breakdowns: ParticipantBreakdown[] }>(
    "/api/cohort-explorer/counts"
  );
};

export default useTotalParticipantsCount;
