import { loadingButtonClasses } from "@mui/lab";
import type {} from "@mui/lab/themeAugmentation";
import {
  breadcrumbsClasses,
  buttonClasses,
  listItemIconClasses,
  listItemTextClasses,
  menuItemClasses,
  outlinedInputClasses,
  tabClasses,
  toggleButtonClasses,
} from "@mui/material";
import { common, grey } from "@mui/material/colors";
import { createTheme, darken, responsiveFontSizes } from "@mui/material/styles";

const base = createTheme({
  palette: {
    primary: {
      main: "#613DE4",
      dark: "#1d1d1f",
      light: "rgba(97, 61, 228, 0.06)",
    },
    secondary: {
      light: "#E8CDE8",
      main: "#FF027C",
    },
    text: {
      graphite: "#5A5B5D",
      link: "#4427b6",
      visited: "#8f1ba0",
    },
    info: {
      main: "#2D68C4",
    },
    header: {
      light: "#EAE8EB",
    },
    common: {
      white: "#fff",
      black: "#1d1d1f",
      almostWhite: "#fafafb",
      toolbarBackground: "#fafafc",
      toolbarBorder: "#e1e1e1",
      pageBackground: "#fff",
      dimmedPageBackground: "#f5f5f7",
      cardFooterText: "#878787",
      cardFooterBackground: "#f7f7f7",
      cardBorder: "#e2e2e4",
      tableHeaderBackground: "#fff",
      searchInputDarkBackground: "#e3e3e6",
    },
    code: {
      main: "#613DE4",
    },
    action: {
      disabledBackground: "",
      disabled: "",
    },
    attention: {
      main: "#E00037",
      dark: darken("#E00037", 0.4),
    },
    status: {
      pending: "#474352",
      running: "#1CD144",
      stopping: "#474352",
      stopped: "#474352",
      failed: "#FF295D",
      secured: "#00a85f",
    },
  },
});

let theme = createTheme({
  palette: base.palette,
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Oxygen-Sans"',
      "Ubuntu",
      "Cantarell",
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
    allVariants: {
      color: base.palette.primary.dark,
      overflowWrap: "anywhere",
    },
    label: {
      color: base.palette.primary.dark,
      opacity: 0.5,
      fontSize: "0.875rem",
      display: "block",
      fontWeight: base.typography.fontWeightMedium,
      marginBottom: 4,
      userSelect: "none",
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.875rem",
      letterSpacing: "0.025em",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "b, strong": {
          fontWeight: 600,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          padding: "4px",
          color: base.palette.primary.dark,
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.07)" },
          "&:active": {
            backgroundColor: "rgba(0, 0, 0, 0.16)",
          },
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.38,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "fit-content",
          minWidth: "fit-content",
          textTransform: "initial",
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.75,
          },
          whiteSpace: "nowrap",
          fontWeight: 500,
          borderRadius: 6,
        },
        sizeSmall: {
          fontSize: 14,
          padding: "2px 8px",
        },
        sizeMedium: {
          fontSize: 14,
        },
        sizeLarge: {
          fontSize: 16,
        },
        contained: {
          color: common.white,
          boxShadow: "none !important",
          transition: "initial",
          "&:hover": {
            boxShadow: "none",
          },
          [`.${loadingButtonClasses.loadingIndicator}`]: {
            color: "#613DE4",
          },
          [`&.${buttonClasses.disabled}`]: {
            backgroundColor: grey[200],
            color: grey[600],
            boxShadow: "none !important",
          },
        },
        containedSecondary: {
          color: base.palette.primary.dark,
          boxShadow: "none !important",
          backgroundColor: common.white,
          [`&.${buttonClasses.disabled}`]: {
            backgroundColor: grey[200],
            color: grey[600],
            "& .MuiButton-startIcon,& .MuiButton-endIcon": { color: grey[500] },
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.07)",
          },
          "&:active": {
            backgroundColor: "rgba(0, 0, 0, 0.16)",
          },
        },
        outlined: {
          [`&.${buttonClasses.disabled}`]: {
            color: grey[600],
            borderColor: grey[200],
            backgroundColor: grey[200],
          },
        },
        outlinedPrimary: {
          color: base.palette.primary.dark,
          borderColor: "rgba(0, 0, 0, 0.12)",
          "&:hover": {
            borderColor: "transparent",
            backgroundColor: "rgba(0, 0, 0, 0.07)",
          },
          "&:active": {
            backgroundColor: "rgba(0, 0, 0, 0.16)",
          },
          [`.${loadingButtonClasses.loadingIndicator}`]: {
            color: base.palette.primary.main,
          },
        },
        outlinedInherit: {
          borderColor: "rgba(0, 0, 0, 0.12)",
          "&:hover": {
            borderColor: "transparent",
            backgroundColor: "rgba(0, 0, 0, 0.07)",
          },
          "&:active": {
            backgroundColor: "rgba(0, 0, 0, 0.16)",
          },
          [`.${loadingButtonClasses.loadingIndicator}`]: {
            color: "rgba(0, 0, 0, 0.87)",
          },
        },
        textPrimary: {
          color: base.palette.primary.dark,
          [`&.${buttonClasses.disabled}`]: {
            color: grey[600],
          },
          "&:hover": {
            backgroundColor: grey[200],
          },
          "&:active": {
            backgroundColor: grey[300],
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.containedSecondary}`]: {
            backgroundColor: grey[200],
            "&:hover": {
              backgroundColor: grey[300],
            },
            "&:active": {
              backgroundColor: grey[400],
            },
          },
          [`&.${buttonClasses.outlinedPrimary}`]: {
            color: base.palette.primary.main,
            borderColor: base.palette.primary.main,
            "&:hover": {
              backgroundColor: base.palette.primary.light,
            },
            "&:disabled": {
              borderColor: grey[600],
              backgroundColor: base.palette.common.white,
            },
            [`.${loadingButtonClasses.loadingIndicator}`]: {
              color: base.palette.primary.main,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          [`&:hover:not(.${outlinedInputClasses.disabled}):not(.${outlinedInputClasses.error}):not(.${outlinedInputClasses.focused})`]:
            {
              [` .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: base.palette.primary.dark,
              },
            },
          [`&:hover.${outlinedInputClasses.disabled}`]: {
            [` .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
          },
          [`&.${outlinedInputClasses.disabled} svg`]: {
            opacity: 0.5,
          },
        },
        input: {
          fontSize: 14,
        },
        multiline: {
          padding: "8px 12px",
        },
        inputMultiline: {
          padding: 0,
          borderRadius: 0,
        },
        inputSizeSmall: {
          padding: "10px 14px",
        },
        adornedStart: {
          paddingLeft: 8,
        },
        inputAdornedStart: {
          paddingLeft: 8,
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: "linear-gradient(180deg, #FFFFFF 0%, #E5D4F7 100%), #FFFFFF",
          },
        },
      ],
    },
    MuiDialog: {
      defaultProps: {
        "aria-labelledby": "dialog-title",
      },
      styleOverrides: {
        paper: {
          borderRadius: 12,
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: "body1",
        fontWeight: 600,
        fontSize: 18,
        id: "dialog-title",
      },
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "common.black",
          position: "relative",
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "20px 24px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          width: "fit-content",
          fontWeight: base.typography.fontWeightMedium,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            elevation: 1,
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          elevation: 1,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          [`&:hover, &.${menuItemClasses.root}:hover`]: {
            color: base.palette.common.white,
            backgroundColor: base.palette.primary.main,
          },
          [`& .${listItemTextClasses.primary}, & .${listItemIconClasses.root}`]: {
            fontSize: "inherit",
            color: "inherit",
            backgroundColor: "inherit",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          overflow: "hidden",
          background: base.palette.common.white,
          boxShadow: "0px 1px 4px 0px #C6C6D2",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          fontSize: "75%",
          backgroundColor: "#808C8D",
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          boxSizing: "border-box",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: base.palette.primary.main,
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 9,
          backgroundColor: "#F6F5FA",
        },
        grouped: {
          textTransform: "none",
          fontWeight: base.typography.fontWeightBold,
          padding: "6px 10px",
          margin: "6px",
          border: "none",
          borderRadius: 6,
          [`&.${toggleButtonClasses.selected}`]: {
            backgroundColor: "#FFF",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);",
          },
          [`&.${toggleButtonClasses.selected}:hover`]: {
            backgroundColor: "#FFF",
          },
          "&:not(:first-of-type)": {
            borderRadius: 6,
          },
          "&:first-of-type": {
            borderRadius: 6,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "unset",
          borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
        },
        fixed: {
          height: "fit-content",
        },
        flexContainer: {
          gap: base.spacing(4),
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          opacity: 0.8,
          paddingBottom: base.spacing(2),
          color: base.palette.primary.dark,
          fontWeight: 500,
          fontSize: 14,
          textTransform: "capitalize",
          minHeight: "fit-content",
          paddingLeft: 0,
          paddingRight: 0,
          minWidth: "auto",
          [`&.${tabClasses.selected}`]: {
            opacity: 1,
            color: base.palette.primary.main,
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: { variant: "dark" },
      styleOverrides: {
        tooltip: ({ ownerState }) => ({
          fontSize: 12,
          ...(ownerState.variant === "light" && {
            color: base.palette.common.black,
            backgroundColor: base.palette.common.white,
            border: `1px solid ${base.palette.grey[300]}`,
            boxShadow: "0px 1px 4px 0px #C6C6D2",
          }),
          ...(ownerState.variant === "dark" && { color: "#EFEFF1", backgroundColor: "#131519" }),
        }),
        arrow: ({ ownerState }) => ({
          ...(ownerState.variant === "light" && {
            color: base.palette.common.white,
            "&::before": {
              border: `1px solid ${base.palette.grey[300]}`,
              boxShadow: "0px 1px 4px 0px #C6C6D2",
            },
          }),
          ...(ownerState.variant === "dark" && {
            color: "#131519",
          }),
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          fontSize: 15,
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        "aria-label": "breadcrumb",
      },
      styleOverrides: {
        root: {
          [`ol > li.${breadcrumbsClasses.li}`]: {
            fontSize: 14,
            color: base.palette.primary.dark,
            a: {
              opacity: 0.72,
              color: "inherit",
              "&:hover": { opacity: 1 },
            },
          },
        },
        separator: {
          marginLeft: 2,
          marginRight: 2,
          opacity: 0.72,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedError: {
          backgroundColor: "rgb(253, 237, 237)",
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        componentsProps: {
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

if (import.meta.env.DEV) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  (window as any).theme = theme;
}

export default theme;
