import { cloneElement, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import rumClient from "~/utils/monitoring";
import useErrorToast from "~/contexts/error-toast";
import useCohorts from "cohorts/hooks/useCohorts";

const CreateCohort = (props: { callToAction: ReactElement }) => {
  const navigate = useNavigate();
  const { mutate } = useCohorts();
  const { showError } = useErrorToast();
  const [creating, setCreating] = useState(false);

  const handleCreate = async () => {
    setCreating(true);

    try {
      const resp = await fetch("/api/cohort-explorer/cohorts", {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!resp.ok) {
        throw Error("There was a problem trying to create cohort");
      }

      mutate();
      const cohort = await resp.json();
      navigate(`/cohorts/${cohort.id}`);
    } catch (e) {
      console.error(e);
      rumClient?.recordError(e);
      showError("Unable to create cohort");
    }

    setCreating(false);
  };

  const boundCTA = cloneElement(props.callToAction, {
    loading: creating,
    onClick: () => handleCreate(),
    ["aria-label"]: "Create Cohort",
  });

  return <Box sx={{ pointerEvents: creating ? "none" : "auto" }}>{boundCTA}</Box>;
};

export default CreateCohort;
