import { Box, styled } from "@mui/material";
import { BoxProps } from "@mui/material/Box/Box";

const StatusIndicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "size",
})<BoxProps & { active: boolean; size?: number }>(({ theme, active, size }) => ({
  height: size ?? 12,
  width: size ?? 12,
  flexShrink: 0,
  borderRadius: "50%",
  backgroundColor: active ? theme.palette.status.running : theme.palette.grey[300],
}));

export default StatusIndicator;
