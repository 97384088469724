import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TextOverflow from "~/components/TextOverflow";
import Project from "rwb/types/interfaces/project";
import useProjects from "rwb/hooks/useProjects";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  project: Project;
}

const { VITE_RWB_API } = import.meta.env;

const LeaveProjectModal = ({ open, onClose, project }: ModalProps) => {
  const baseUrl = `${VITE_RWB_API}/projects`;
  const navigate = useNavigate();
  const { data: projects, mutate: mutateProjects } = useProjects();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleClose = (_: object, reason: string) => {
    // Disable ability to escape modal while request is processing
    if (submitting && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  const handleLeave = async () => {
    setError("");
    setSubmitting(true);

    const resp = await fetch(`${baseUrl}/${project.id}/leave`, {
      method: "PUT",
    });

    setSubmitting(false);

    if (resp.ok) {
      const filteredProjectList = projects?.data?.filter((w) => w.id !== project.id) ?? [];
      // Optimistic update
      mutateProjects({
        data: filteredProjectList,
        total: (projects?.total ?? 1) - 1,
      });
      navigate("/workbenches", { replace: true });
    } else {
      setError("Unable to leave workbench");
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        <TextOverflow>Leave &quot;{project.title}&quot;</TextOverflow>
      </DialogTitle>
      <DialogContent sx={{ gap: 2, fontSize: 14 }}>
        {error && <Alert severity="error">{error}</Alert>}
        Are you sure you want to leave this workbench? You will lose access to this workbench’s
        compute environments and data
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={submitting} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={submitting}
          onClick={handleLeave}
          sx={{
            backgroundColor: "attention.main",
            "&:hover": { backgroundColor: "attention.dark" },
          }}
        >
          Leave
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveProjectModal;
