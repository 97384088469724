import queryString from "query-string";
import { useFetcher } from "~/hooks/useFetcher";
import {
  useQueryParams,
  StringParam,
  BooleanParam,
  withDefault,
  NumberParam,
} from "use-query-params";
import Project from "rwb/types/interfaces/project";
import { DEFAULT_SORT, DEFAULT_SORT_DESCENDING } from "rwb/pages/projects/types/sort";
import { DEFAULT_FILTER } from "rwb/pages/projects/types/filter";
import EnvironmentStatus from "rwb/pages/project/types/enums/environmentStatus";

const { VITE_RWB_API } = import.meta.env;

export const PAGE_SIZE = 20;

export type ProjectsResponse = { data: Project[]; total: number };

const useProjects = (limit = PAGE_SIZE) => {
  const [query] = useQueryParams({
    page: withDefault(NumberParam, 0),
    sort_field: withDefault(StringParam, DEFAULT_SORT),
    sort_descending: withDefault(BooleanParam, DEFAULT_SORT_DESCENDING),
    filter_field: withDefault(StringParam, DEFAULT_FILTER),
  });

  const { page, sort_field, sort_descending, filter_field } = query;

  const qs = queryString.stringify({
    limit,
    skip: page ? (page - 1) * limit : 0,
    sort_field,
    sort_descending,
    filter_type: filter_field,
  });

  return useFetcher<ProjectsResponse>(`${VITE_RWB_API}/projects?${qs}`, {
    refreshInterval: (latestData?: ProjectsResponse) => {
      const envStatusPending = latestData?.data.some((project) =>
        project.environments?.some((env) => {
          return (
            [EnvironmentStatus.PENDING, EnvironmentStatus.STOPPING].includes(env.status) ||
            env.container_status === EnvironmentStatus.PENDING
          );
        })
      );

      return envStatusPending ? 3000 : 0;
    },
  });
};

export default useProjects;
