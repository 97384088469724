import { generatePath, Navigate, RouteObject, useLocation, useParams } from "react-router-dom";

const Redirect = (props: { path: string }) => {
  const location = useLocation();
  const params = useParams();
  const resolvedPath = generatePath(props.path, params);
  return <Navigate to={{ pathname: resolvedPath, search: location.search }} replace />;
};

const redirects: RouteObject[] = [
  // Handle redirecting users who may have bookmarked legacy login page route
  // Login flow now starts with auth0 always
  { path: "login", element: <Redirect path="/" /> },
  {
    path: "workbench/*",
    element: <Redirect path="/workbenches/*" />,
  },
  {
    path: "workbenches/:projectId/catalog/datasets",
    element: <Redirect path="/workbenches/:projectId/catalog/tables" />,
  },
  {
    path: "workbenches/:projectId/catalog/datasets/:tableFQN",
    element: <Redirect path="/workbenches/:projectId/catalog/tables/:tableFQN" />,
  },
  {
    path: "workbenches/:projectId/catalog/variables",
    element: <Redirect path="/workbenches/:projectId/catalog/columns" />,
  },
  {
    path: "workbenches/:projectId/environment/:envId",
    element: <Redirect path="/workbenches/:projectId/environments/:envId" />,
  },
  {
    path: "catalog/datasets",
    element: <Redirect path="/catalog/tables" />,
  },
  {
    path: "catalog/datasets/:tableFQN",
    element: <Redirect path="/catalog/tables/:tableFQN" />,
  },
  {
    path: "catalog/variables",
    element: <Redirect path="/catalog/columns" />,
  },
  { path: "data-dashboard", element: <Redirect path="/data-overview" /> },
  { path: "projects", element: <Redirect path="/workbenches" /> },
  { path: "projects/*", element: <Redirect path="/workbenches/*" /> },
  {
    path: "projects/:id/files/project",
    element: <Redirect path="/workbenches/:id/files/workbench" />,
  },
  {
    path: "projects/:id/files/project/*",
    element: <Redirect path="/workbenches/:id/files/workbench/*?" />,
  },
  // Project level catalog view moved to top level route
  {
    path: "projects/:id/catalog/*",
    element: <Redirect path="/catalog*?" />,
  },
  {
    path: "files/projects",
    element: <Redirect path="/files/workbenches" />,
  },
  {
    path: "files/projects/*",
    element: <Redirect path="/files/workbenches/*?" />,
  },
];

export default redirects;
