import { useMatch } from "react-router-dom";
import { SWRResponse } from "swr";
import { Alert, Button, Skeleton, Stack, Typography as T } from "@mui/material";
import { PlusIcon, CardsIcon } from "~/assets/icons";
import CardGrid from "~/components/CardGrid";
import LimitedPagination from "~/components/LimitedPagination";
import { PAGE_SIZE, ProjectsResponse } from "rwb/hooks/useProjects";
import CreateProject from "rwb/pages/projects/components/CreateProject";
import ProjectCard from "rwb/pages/projects/components/ProjectCard";

const Skeletons = ({ limit }: { limit: number }) => (
  <>
    {Array.from({ length: Math.min(limit, 8) }).map((_, i) => (
      <Skeleton key={i} variant="rounded" height={205} />
    ))}
  </>
);

const EmptyPlaceholder = () => {
  const showWelcome = !!useMatch("/workbenches");
  return (
    <Stack gap={3} alignItems="center">
      <CardsIcon />
      <Stack gap={1} alignItems="center">
        {showWelcome && <T variant="h6">Welcome to Workbenches</T>}
        <T variant={showWelcome ? "body2" : "body1"}>
          To get started, create your first workbench.
        </T>
      </Stack>
      <CreateProject
        callToAction={
          <Button variant="contained" startIcon={<PlusIcon />}>
            New Workbench
          </Button>
        }
      />
    </Stack>
  );
};

interface ProjectListProps {
  data: SWRResponse<ProjectsResponse>;
  pageSize?: number;
  showPagination?: boolean;
}

const ProjectList = ({ data, pageSize = PAGE_SIZE, showPagination = true }: ProjectListProps) => {
  const { data: projects, isLoading, error, mutate } = data;
  const showEmpty = !error && !isLoading && !projects?.total;

  if (error) {
    return (
      <Alert severity="error" variant="outlined" sx={{ my: 2 }}>
        Unable to load workbenches
      </Alert>
    );
  }

  if (showEmpty) {
    return <EmptyPlaceholder />;
  }

  return (
    <>
      <CardGrid>
        {isLoading ? (
          <Skeletons limit={pageSize} />
        ) : (
          projects?.data.map((project) => (
            <ProjectCard key={project.id} data={project} mutateProjects={mutate} />
          ))
        )}
      </CardGrid>
      {showPagination && (
        <LimitedPagination total={projects?.total ?? 0} defaultPageSize={pageSize} />
      )}
    </>
  );
};

export default ProjectList;
